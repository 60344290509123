import { render, staticRenderFns } from "./email.vue?vue&type=template&id=8b6d3cb8&scoped=true&"
import script from "./email.vue?vue&type=script&lang=ts&"
export * from "./email.vue?vue&type=script&lang=ts&"
import style0 from "./email.vue?vue&type=style&index=0&id=8b6d3cb8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b6d3cb8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})
