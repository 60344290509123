import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=6fd3de56&scoped=true&"
import script from "./confirm.vue?vue&type=script&lang=ts&"
export * from "./confirm.vue?vue&type=script&lang=ts&"
import style0 from "./confirm.vue?vue&type=style&index=0&id=6fd3de56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd3de56",
  null
  
)

/* custom blocks */
import block0 from "./confirm.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VCol,VRow,VTextField,VTooltip})
