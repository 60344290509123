var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.inputClasses},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{class:_vm.labelClasses,staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"vue-tel-input"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('vue-tel-input',{ref:"vue-tel-input",attrs:{"autocomplete":"off","name":"vue-tel-input","mode":"international","inputOptions":{showDialCode: true},"placeholder":"","preferredCountries":_vm.preferredCountries},on:{"focus":_vm.onFocus,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_vm._v(" "+_vm._s(null)+" ")]},proxy:true}]),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{class:{
          'v-messages': true,
          'theme--light': true,
          'error--text': _vm.error,
        }},[_c('div',{class:{
            'v-messages__wrapper': true,
            'primary--text': _vm.isFocused && !_vm.error
          }},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.messages.length > 0 ? _vm.messages[0] : null)+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }