









import Vue from 'vue';

import '@/plugins/vue-mq';
import { updateLocale } from '@/plugins/i18n';

import FreeTrial from './views/FreeTrial.vue';

// @ts-ignore
export default Vue.extend({
  name: 'App',

  components: {
    FreeTrial,
  },

  data: () => ({
    resizeAnimationFrameHandle: null as null | number,
  }),

  created() {
    updateLocale();
    this.setupViewportHeight();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.windowResizeListener);
    this.cancelResizeAnimationFrame();
  },

  methods: {
    cancelResizeAnimationFrame() {
      if (this.resizeAnimationFrameHandle) {
        window.cancelAnimationFrame(this.resizeAnimationFrameHandle);
        this.resizeAnimationFrameHandle = null;
      }
    },

    setupViewportHeight() {
      this.updateCSSViewportHeightVariable();

      // Resize listener
      window.addEventListener('resize', this.windowResizeListener);
    },

    updateCSSViewportHeightVariable() {
      // Sets the current viewport height
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    windowResizeListener() {
      this.cancelResizeAnimationFrame();

      this.resizeAnimationFrameHandle = window.requestAnimationFrame(() => {
        this.updateCSSViewportHeightVariable();
      });
    },
  },
});
