




















































import { getTestimonials } from '@/utils';

const moment = require('moment');

export default {
  name: 'Testimonials',

  data() {
    return {
      testimonials: getTestimonials(),
    };
  },

  methods: {
  },

  computed: {
    carouselInterval() {
      return moment.duration(15, 'seconds').asMilliseconds();
    },
  },
};
