










import PhoneInput from '@/components/form/PhoneInput.vue';
import { validatePhone } from '@/utils/validators';

export default {
  name: 'Phone',

  components: {
    PhoneInput,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      manualError: false,
    };
  },

  computed: {
    inputValue: {
      get():string {
        return this.value;
      },

      set(value):void {
        this.$emit('input', value);
      },
    },

    messages(): string[] {
      const result = this.valid(this.inputValue);
      if (this.manualError && result !== true) {
        return [this.$t(`error.${result}`)];
      }

      return [this.$t('hint.phone')];
    },
  },

  methods: {
    onNext() {
      if (this.valid(this.inputValue as string) === true) {
        this.$emit('next');
      } else {
        this.manualError = true;
      }
    },

    valid(value: string): boolean | string {
      return validatePhone(value.replace(/\s/g, ''));
    },
  },
};
