
























import { getContactSizes } from '@/utils';
import { validateContacts } from '@/utils/validators';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'Contacts',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    sliderLabels: Object.values(i18n.messages[i18n.locale].label.contactSize),
    manualError: false,
  }),

  computed: {
    inputValue: {
      get():number {
        const sizes = getContactSizes();

        return Object.values(sizes).indexOf(this.value);
      },

      set(value):void {
        const sizes = getContactSizes();

        this.$emit('input', Object.values(sizes)[value]);
      },
    },

    contactSizeText() {
      const values = getContactSizes();
      const key = Object.keys(values)[this.inputValue];

      return this.$t(`label.contactSize.${key.toLowerCase()}`);
    },
  },

  methods: {
    onNext() {
      if (this.valid(this.inputValue) === true) {
        this.$emit('next');
      } else {
        this.manualError = true;
      }
    },

    valid(value: number): boolean | string {
      return validateContacts(value);
    },
  },
};
