import Vue from 'vue';
import VueRouter from 'vue-router';
import FreeTrial from '../views/FreeTrial.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'free-trial',
    component: FreeTrial,
  },
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
