















import { validatePassword } from '@/utils/validators';

export default {
  name: 'Password',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      manualError: false,
      showPassword: false,
    };
  },

  computed: {
    inputValue: {
      get():string {
        return this.value;
      },

      set(value):void {
        this.$emit('input', value);
      },
    },

    messages(): string[] {
      const result = this.valid(this.inputValue);

      if (this.manualError && result !== true) {
        return [this.$t(`error.${result}`)];
      }

      return [this.$t('hint.password')];
    },

    rules() {
      return this.manualError
        ? [this.valid(this.inputValue)]
        : [];
    },
  },

  methods: {
    onNext() {
      if (this.valid(this.inputValue as string) === true) {
        this.$emit('next');
      } else {
        this.manualError = true;
      }
    },

    valid(value:string): boolean | string {
      return validatePassword(value);
    },
  },
};
