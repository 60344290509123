
























import { getLongevities } from '@/utils';
import { validateLongevity } from '@/utils/validators';

export default {
  name: 'Employees',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    sliderLabels: [
      '<1',
      '1-2',
      '3-5',
      '6-10',
      '11-20',
      '21+',
    ],
    manualError: false,
  }),

  computed: {
    inputValue: {
      get():number {
        const longevities = getLongevities();

        return Object.values(longevities).indexOf(this.value);
      },

      set(value):void {
        const longevities = getLongevities();

        this.$emit('input', Object.values(longevities)[value]);
      },
    },

    longevitySizeText() {
      const values = getLongevities();
      const key = Object.keys(values)[this.inputValue];

      return this.$t(`label.longevity.${key.toLowerCase()}`);
    },
  },

  methods: {
    onNext() {
      if (this.valid(this.inputValue) === true) {
        this.$emit('next');
      } else {
        this.manualError = true;
      }
    },

    valid(value: number): boolean | string {
      return validateLongevity(value);
    },
  },
};
