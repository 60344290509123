import get from 'lodash.get';

import ObjectivesConfiguration from '@/config/objectives.json';
import Testimonials from '@/config/testimonials.json';
import ContactSize from '@/config/contactSize.json';
import EmployeeSize from '@/config/employeeSize.json';
import Longevity from '@/config/longevity.json';

export function getConfig(path: string|number, defaultValue?: any) : any {
  //* This configuration object is global
  //* This way we can remove it from the build process.
  //* It's loaded by public/index.html
  return get(window.ChanseyConfiguration, path, defaultValue);
}

export function getTestimonials(): Testimonial[] {
  return Testimonials;
}

export function getObjectives(): Objective[] {
  const { objectives }: any = ObjectivesConfiguration;

  return Object.keys(objectives).map((objective: any) => ({
    icon: objectives[objective] || 'help_outline',
    topic: objective,
    titleName: `label.objectives.${objective}`,
  }));
}

export function getContactSizes() {
  return ContactSize;
}

export function getEmployeeSizes() {
  return EmployeeSize;
}

export function getLongevities() {
  return Longevity;
}

type Testimonial = {
   author: string,
   company: string,
   logo: string,
   text: string,
}

type Objective = {
  icon: string,
  topic: string,
  titleName: string
}
