






















































import { getConfig } from '@/utils';

export default {
  name: 'PhoneInput',
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Phone',
    },
    messages: {
      type: Array,
      default: () => [],
    },
    preferredCountries: {
      type: Array,
      default: () => getConfig('i18n.preferredCountries') as string[],
    },
    value: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },

  created() {
    window.addEventListener('keyup', this.keyup);
  },

  mounted() {
    this.$refs['vue-tel-input'].focus();
    this.isFocused = true;
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyup);
  },

  methods: {
    onFocus() {
      this.isFocused = true;
    },

    onBlur() {
      this.isFocused = false;
    },

    keyup(event) {
      this.$emit('keyup', event);
    },
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    labelClasses() {
      const classes = ['v-label', 'theme--light'];

      if (this.isFocused) {
        classes.push(`${this.variant}--text`);
      }

      if (this.isFocused || (this.value && this.value.length)) {
        classes.push('v-label--active');
      }

      if (this.error) {
        classes.push('error--text');
      }

      return classes;
    },

    inputClasses() {
      const classes = ['v-input', 'theme--light', 'v-text-field', 'v-text-field--is-booted'];

      if (this.isFocused) {
        classes.push('v-input--is-focused', `${this.variant}--text`);
      }

      if (this.isFocused || (this.value && this.value.length)) {
        classes.push('v-input--is-label-active');
      }

      if (this.error) {
        classes.push('v-input--has-state', 'error--text');
      }

      return classes;
    },
  },
};
