import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { router } from '@/router';
import { getConfig } from '@/utils';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {} as any;

  for (const key of locales.keys()) {
    const matched = key.match(/([a-z0-9]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  }

  return messages;
}

function getLocale() {
  const queryLanguage = router.currentRoute.query.lang;
  const browserPreferredLanguage = window.navigator.language.slice(0, 2);
  const supportedLocales = getConfig('i18n.supportedLocales', ['fr', 'en']);
  let locale = process.env.VUE_APP_I18N_LOCALE || 'en';

  if (queryLanguage && supportedLocales.includes(queryLanguage)) {
    locale = queryLanguage;
  } else if (browserPreferredLanguage && supportedLocales.includes(browserPreferredLanguage)) {
    locale = browserPreferredLanguage;
  }

  return locale;
}

export const i18n = new VueI18n({
  locale: getLocale(),
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});

export function updateLocale() {
  i18n.locale = getLocale();
}
