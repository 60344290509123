
























import { getEmployeeSizes } from '@/utils';
import { validateEmployees } from '@/utils/validators';

export default {
  name: 'Employees',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    sliderLabels: [
      '1',
      '2-4',
      '5-20',
      '21-99',
      '100+',
    ],
    manualError: false,
  }),

  computed: {
    inputValue: {
      get():number {
        const sizes = getEmployeeSizes();

        return Object.values(sizes).indexOf(this.value);
      },

      set(value):void {
        const sizes = getEmployeeSizes();

        this.$emit('input', Object.values(sizes)[value]);
      },
    },

    employeeSizeText() {
      const values = getEmployeeSizes();
      const key = Object.keys(values)[this.inputValue];

      return this.$t(`label.employeeSize.${key.toLowerCase()}`);
    },
  },

  methods: {
    onNext() {
      if (this.valid(this.inputValue) === true) {
        this.$emit('next');
      } else {
        this.manualError = true;
      }
    },

    valid(value: number): boolean | string {
      return validateEmployees(value);
    },
  },
};
