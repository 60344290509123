import Vue from 'vue';
import VueGtm from 'vue-gtm';

import { getConfig } from '@/utils';

import { router } from '@/router';

Vue.use(VueGtm, {
  id: getConfig('product.gtm.id') as string,
  enabled: getConfig('product.gtm.enabled') as string,
  debug: getConfig('product.gtm.debug') as string,
  vueRouter: router,
});
