import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import VueTelInput from 'vue-tel-input';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
import './plugins/vue-gtm';
import { i18n } from './plugins/i18n';
import axios from './plugins/axios';

Vue.config.productionTip = false;

Vue.use(VueCompositionApi);
Vue.use(VueTelInput);
Vue.use(axios);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
