





























































































































import moment from 'moment';
import Sha256 from 'sha256';
import { setCookie } from 'tiny-cookie';
import { Interpreter, State } from 'xstate';

import { getConfig } from '@/utils';

import {
  getService,
  FreeTrialSchema,
  FreeTrialContext,
  FreeTrialEvent,
} from '@/machines/freeTrial';
import FormEmail from '@/views/forms/email.vue';
import FormContacts from '@/views/forms/contacts.vue';
import FormEmployees from '@/views/forms/employees.vue';
import FormLongevity from '@/views/forms/longevity.vue';
import FormName from '@/views/forms/name.vue';
import FormPhone from '@/views/forms/phone.vue';
import FormWebsite from '@/views/forms/website.vue';
import FormObjective from '@/views/forms/objective.vue';
import FormPassword from '@/views/forms/password.vue';
import FormConfirm from '@/views/forms/confirm.vue';

const forms = [
  'name',
  'email',
  'phone',
  'website',
  'contacts',
  'employees',
  'longevity',
  // 'objective',
  'password',
  'confirm',
];

const excludedFromQueryParams = ['password', 'confirm'];

export default {
  name: 'FormContainer',

  components: {
    FormEmail,
    FormContacts,
    FormEmployees,
    FormLongevity,
    FormName,
    FormPhone,
    FormWebsite,
    FormObjective,
    FormPassword,
    FormConfirm,
  },

  data() {
    return {
      busySignup: false,
      currentState: null as (State<FreeTrialContext, FreeTrialEvent> | null),
      form: {
        name: '',
        email: '',
        phone: '',
        website: '',
        contacts: '0',
        employees: '1',
        longevity: '0-1',
        // objective: '',
        password: '',
        terms: false,
        language: this.$i18n.locale,
      },
      service: null as (Interpreter<FreeTrialContext, FreeTrialSchema, FreeTrialEvent> | null),
      signupError: null,
    };
  },

  created() {
    this.service = this.getXStateService();
    this.service
      .onTransition(this.onTransition)
      .start();

    window.addEventListener('keyup', this.handleEnterKey);

    this.form = {
      ...this.getInitialData(),
      terms: false,
      language: this.$i18n.locale,
      password: '',
    };
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.handleEnterKey);
    this.sendGTMOnboardingSignup('signup-abort');
    this.service.stop();
  },

  computed: {
    currentForm():string {
      if (!this.currentState || !this.currentState.matches('form')) {
        return '';
      }

      return this.currentState.context.currentForm;
    },

    currentStateValidEvents():string[] {
      if (this.currentState && this.currentState.nextEvents) {
        return this.currentState.nextEvents.filter((nextEvent) => {
          if (!this.service || !this.currentState) {
            return false;
          }

          return this.service.machine.transition(
            this.currentState,
            { type: nextEvent } as FreeTrialEvent,
          ).changed;
        });
      }

      return [];
    },

    currentStepNumber():number {
      if (this.currentState) {
        const localService = this.getXStateService(this.currentState.context);
        localService.start();

        if (localService.state.matches('form')) {
          let numberOfSteps = -1;

          do {
            numberOfSteps += 1;
            localService.send('NEXT');
          } while (localService.state.matches('form'));

          return this.totalNumberOfSteps - numberOfSteps;
        }
      }

      return -1;
    },

    totalNumberOfSteps():number {
      if (this.currentState) {
        const localService = this.getXStateService();
        localService.start();

        if (localService.state.matches('form')) {
          let numberOfSteps = 0;

          do {
            numberOfSteps += 1;
            localService.send('NEXT');
          } while (localService.state.matches('form'));

          return numberOfSteps;
        }
      }

      return -1;
    },

    tooManyRequestSpan(): string {
      if (!this.signupError || !this.signupError.data) {
        return null;
      }

      return this.signupError.data.seconds;
    },

    redirectUrl(): string {
      return `${getConfig('leadfox.redirectUrl', 'https://localhost/lf/#/templates')}?lang=${this.$i18n.locale}`;
    },
  },

  methods: {
    cancelMiddleClick(event: MouseEvent) {
      (event.currentTarget as HTMLButtonElement).blur();
    },

    getXStateService(context?:FreeTrialContext): Interpreter<
      FreeTrialContext,
      FreeTrialSchema,
      FreeTrialEvent
    > {
      const serviceContext = context || {
        currentForm: '',
        data: {
          ...this.$route.query.name ? { name: this.$route.query.name } : {},
          ...this.$route.query.email ? { email: this.$route.query.email } : {},
          ...this.$route.query.phone ? { phone: this.$route.query.phone } : {},
          ...this.$route.query.website ? { website: this.$route.query.website } : {},
          ...this.$route.query.contacts ? { contacts: this.$route.query.contacts } : {},
          ...this.$route.query.employees ? { employees: this.$route.query.employees } : {},
          ...this.$route.query.longevity ? { longevity: this.$route.query.longevity } : {},
          ...this.$route.query.objective ? { objective: this.$route.query.objective } : {},
          ...this.$route.query.password ? { password: this.$route.query.password } : {},
          ...this.$route.query.confirm ? { confirm: this.$route.query.confirm } : {},
        },
      };

      return getService(forms, serviceContext);
    },

    onTransition(state: State<FreeTrialContext, FreeTrialEvent>) {
      this.currentState = state;
    },

    sendNextToCurrentForm() {
      if (this.currentState
        && Object.prototype.hasOwnProperty.call(this.$refs, this.currentForm)
      ) {
        (this.$refs[this.currentForm] as any).onNext();
      }
    },

    handleEnterKey(event) {
      if (event.code === 'Enter' || event.keyCode === 13) {
        this.sendNextToCurrentForm();
      }
    },

    doBack() {
      this.signupError = null;
      this.service.send('BACK');
    },

    async doSignup() {
      try {
        this.busySignup = true;

        // Sets the timezone to the current one the user is
        if (Intl && Intl.DateTimeFormat) {
          const dateTimeFormat = Intl.DateTimeFormat();

          if (dateTimeFormat.resolvedOptions) {
            const resolvedOptions = dateTimeFormat.resolvedOptions();

            if (resolvedOptions.timeZone) {
              this.form.timezone = resolvedOptions.timeZone;
            }
          }
        }

        const userResult = await this.doCreateUser();

        // eslint-disable-next-line no-underscore-dangle
        this.setCookies(userResult.login.jwt, userResult.clients[0]._id);
        this.sendGTMOnboardingSignup('signup-end');

        const isMeetingPersona = this.form.employees !== '1' || this.form.longevity !== '0-1';
        this.sendGTMOnboardingSignup(`signup-sql-${isMeetingPersona ? 'a' : 'b'}`);

        this.busySignup = false;
        this.service.stop();

        // Redirect
        window.location.href = this.redirectUrl;
      } catch (e) {
        this.busySignup = false;
        this.signupError = e;
      }
    },

    async doCreateUser() {
      try {
        const splittedName = this.form.name.trim().split(' ');
        const firstname = splittedName.shift().trim();
        const lastname = splittedName.join(' ').trim();

        const data = {
          ...this.form,
          ...this.getUtmData(),
          ...this.getTapfiliateData(),
          password: Sha256(this.form.password),
          language: this.$i18n.locale,
          signuporigin: 'signup',
          firstname,
          lastname,
          name: undefined,
          terms: undefined,
          industry: this.$route.query.niche ? this.$route.query.niche : undefined,
        };

        const result = await this.$axios.post('user/signup', data);

        const connectResult = await this.connect(this.form.email, this.form.password);

        const clients = await this.getClients(connectResult.jwt);

        return {
          user: result.data,
          clients,
          login: {
            jwt: connectResult.jwt,
            mode: connectResult.mode,
          },
        };
      } catch (e) {
        if (!e.response) {
          throw e;
        }

        switch (e.response.status) {
          // The user already exists
          case 409: {
            throw new Error('userAlreadyExists');
          }

          // Validation error
          case 422: {
            throw new Error('userValidation');
          }

          case 429: {
            throw this.tooManyRequestsError(e.response.data);
          }

          // Unknown error
          default: {
            throw new Error('userUnknown');
          }
        }
      }
    },

    async connect(email: string, password: string) {
      try {
        const result = await this.$axios.post('auth', { email, password: Sha256(password) });
        return result.data;
      } catch (e) {
        switch (e.response.status) {
          case 429: {
            throw this.tooManyRequestsError(e.response.data);
          }

          default: {
            throw new Error('authLogin');
          }
        }
      }
    },

    async getClients(jwt: string) {
      try {
        const result = await this.$axios.get('/client', {
          headers: {
            Authorization: `JWT ${jwt}`,
          },
        });

        return result.data;
      } catch (e) {
        throw new Error('loginUnknown');
      }
    },

    setCookies(jwt: string, client: string) {
      const cookieDomain = getConfig('leadfox.cookie.domain', 'app.leadfox.co');

      const expirationDate = moment();
      expirationDate.add(
        getConfig('leadfox.cookie.expiration.amount', 14),
        getConfig('leadfox.cookie.expiration.unit', 'days'),
      );
      expirationDate.milliseconds(0);
      const expirationDateIso = expirationDate.toISOString();

      setCookie('leadfox_session', jwt, {
        domain: cookieDomain,
        expires: expirationDateIso,
        secure: true,
      });

      setCookie('leadfox_client', client, {
        domain: cookieDomain,
        expires: expirationDateIso,
        secure: true,
      });

      setCookie('leadfox_expiration', expirationDateIso, {
        domain: cookieDomain,
        expires: expirationDateIso,
        secure: true,
      });
    },

    async sendLeadToHubspot() {
      if (!this.form.email) {
        return;
      }

      const data = {
        contact:
        {
          ...this.getHubspotFormData(),
          ...this.getUtmData(),
          language: this.$i18n.locale,
          niche: this.$route.query.niche ? this.$route.query.niche : undefined,
        },
      };

      this.$axios.post('/user/signup/sendHubspot', data);
    },

    tooManyRequestsError(serverResponse) {
      const errorObject = new Error('tooManyRequests') as any;
      const secondsRegex = new RegExp('(\\d+)s');
      errorObject.data = {
        seconds: serverResponse.message.match(secondsRegex)[1],
      };
      return errorObject;
    },

    onNext(sendGTM = false) {
      this.service.send('NEXT');

      if (!this.form.email) {
        return;
      }

      this.sendLeadToHubspot();

      if (sendGTM) {
        this.sendGTMOnboardingSignup('signup-start');
      }
    },

    sendGTMOnboardingSignup(gtmEventName) {
      this.$gtm.trackEvent({
        event: gtmEventName,
        ...this.getMarketing(),
      });
    },

    getMarketing() {
      const marketingFields = ['email', 'name', 'phone', 'language', 'phone', 'website'];

      const marketing = {
        ...Object.assign({}, ...marketingFields.map((entry) => {
          if (this.form[entry]) {
            return {
              [entry]: this.form[entry],
            };
          }

          return null;
        }).filter(Boolean)),
      };

      return marketing;
    },

    getInitialData() {
      const fields = Object.keys(this.form);

      return Object.assign({}, ...fields.filter(entry => !excludedFromQueryParams.includes(entry)).map((entry) => {
        const value = this.$route.query[entry];
        return { [entry]: value || this.form[entry] };
      }));
    },

    getUtmData() {
      const fields = [
        'utm_campaign',
        'utm_campaign_signup',
        'utm_source',
        'utm_source_signup',
        'utm_term',
        'utm_term_signup',
        'utm_content',
        'utm_content_signup',
        'utm_medium',
        'utm_medium_signup',
      ];

      return Object.assign({}, ...fields.map((entry) => {
        const value = this.$route.query[entry];

        return value ? { [entry]: value } : null;
      }).filter(Boolean));
    },

    getTapfiliateData() {
      const tapA = this.$route.query.tap_a;
      const tapS = this.$route.query.tap_s;

      const tapClickCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('tap_click'));

      const [, tapClickId] = tapClickCookie ? tapClickCookie.split('=') : [null];

      return {
        ...tapA && { tapA },
        ...tapS && { tapS },
        ...tapClickId && { tapClickId },
      };
    },

    getHubspotFormData() {
      const hubspotFields = ['email', 'phone', 'language', 'phone', 'website', 'longevity', 'employees', 'contacts'];

      const hubspot = {
        ...Object.assign({}, ...hubspotFields.map((entry) => {
          if (this.form[entry] != null && this.form[entry] !== '') {
            return {
              [entry]: this.form[entry],
            };
          }

          return null;
        }).filter(Boolean)),
      };

      const splittedName = this.form.name.trim().split(' ');
      const firstname = splittedName.shift().trim();
      const lastname = splittedName.join(' ').trim();

      return { ...hubspot, firstname, lastname };
    },
  },
};
