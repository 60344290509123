import Vue, { VueConstructor } from 'vue';
import axios, { AxiosStatic } from 'axios';
import { getConfig } from '@/utils';

const plugin = {
  // eslint-disable-next-line no-shadow
  install(Vue: VueConstructor<Vue>) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$axios = axios.create({
      baseURL: `${getConfig('rest.url')}/${getConfig('rest.version')}/`,
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  },
};

export default plugin;

declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
  }
}
