

















import { getConfig } from '@/utils';
import { validateEmail } from '@/utils/validators';

export default {
  name: 'Email',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      emailExists: false,
      manualError: false,
    };
  },

  computed: {
    inputValue: {
      get():string {
        return this.value;
      },

      set(value):void {
        this.$emit('input', value);
      },
    },

    messages(): string[] {
      const result = this.valid(this.inputValue);

      if (this.manualError && (result !== true || this.emailExists)) {
        return [this.$t(`error.${this.emailExists ? 'emailAlreadyExists' : result}`)];
      }

      return [this.$t('hint.email')];
    },

    rules() {
      return this.manualError
        ? [this.valid(this.inputValue)]
        : [];
    },

    loginUrl() {
      return `${getConfig('leadfox.loginUrl', 'https://app.leadfox.co/lf/login')}?lang=${this.$i18n.locale}`;
    },
  },

  watch: {
    inputValue(value: string) {
      this.emailExists = false;
    },
  },

  methods: {
    async onNext() {
      if (this.valid(this.inputValue as string) !== true) {
        this.manualError = true;
        return;
      }

      const existingResult = await this.validateExistingEmail();

      if (existingResult.valid) {
        this.$emit('next');
      } else {
        this.manualError = true;
        this.emailExists = true;
      }
    },

    valid(value:string): boolean | string {
      return validateEmail(value);
    },

    async validateExistingEmail() {
      const response = await this.$axios.post('user/email/validate', {
        email: this.inputValue,
      });
      return response.data;
    },
  },
};
