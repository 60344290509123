// https://xstate.js.org/viz/?gist=a11db8f49a443b3ad56a42f68bbc25a2
import {
  Machine,
  assign,
  interpret,
  Interpreter,
  StateMachine,
} from 'xstate';

export interface FreeTrialSchema {
  states: {
    idle: {};
    form: {};
    confirm: {};
  };
}

export interface FreeTrialContext {
  currentForm: string;
  data: {
    [key: string]: string | number;
  }
}

export type FreeTrialEvent =
  | { type: 'BACK' }
  | { type: 'NEXT' };

/**
 * Returns an instance of the free trial machine
 * @param forms
 */
function getMachine(forms: string[]): StateMachine<
  FreeTrialContext,
  FreeTrialSchema,
  FreeTrialEvent
> {
  function getNextForm(context:FreeTrialContext, currentForm?:string): string {
    let currentIndex = -1;

    if (currentForm) {
      currentIndex = forms.indexOf(currentForm);
    }

    return forms
      .filter((_, index) => index > currentIndex)
      .find(form => !Object.prototype.hasOwnProperty.call(context.data, form)) || '';
  }

  function getBackForm(context:FreeTrialContext, currentForm?:string): string {
    let currentIndex = forms.length;

    if (currentForm) {
      if (currentForm === 'confirm') {
        return '';
      }

      currentIndex = forms.indexOf(currentForm);
    }

    return forms
      .filter((_, index) => index < currentIndex)
      .reverse()
      .find(form => !Object.prototype.hasOwnProperty.call(context.data, form)) || '';
  }

  return Machine<FreeTrialContext, FreeTrialSchema, FreeTrialEvent>({
    id: 'freeTrial',
    initial: 'idle',
    context: {
      currentForm: '',
      data: {},
    },
    states: {
      idle: {
        on: {
          '': [{
            target: 'form',
            actions: ['init'],
          }],
        },
      },
      form: {
        on: {
          BACK: [{
            target: 'form',
            cond: 'hasBack',
            actions: [assign({
              currentForm: context => getBackForm(context, context.currentForm),
            })],
          }],
          NEXT: [
            {
              target: 'form',
              cond: 'hasNext',
              actions: [assign({
                currentForm: context => getNextForm(context, context.currentForm),
              })],
            },
            {
              target: 'confirm',
              actions: [assign({
                currentForm: context => getBackForm(context),
              })],
            },
          ],
        },
      },
      confirm: {
        on: {
          BACK: [{
            target: 'form',
            actions: [assign({
              currentForm: context => getBackForm(context),
            })],
          }],
        },
      },
    },
  }, {
    actions: {
      init: assign({
        currentForm: (context) => {
          if (context.currentForm) {
            return context.currentForm;
          }

          return getNextForm(context);
        },
      }),
    },
    guards: {
      hasBack: context => !!getBackForm(context, context.currentForm),
      hasNext: context => !!getNextForm(context, context.currentForm),
    },
  });
}

/**
 * Returns a service based on the free trial machine
 * @param forms
 * @param initialContext
 */
export function getService(forms: string[], initialContext?:FreeTrialContext): Interpreter<
  FreeTrialContext,
  FreeTrialSchema,
  FreeTrialEvent
> {
  let machineToInterpret = getMachine(forms);

  if (initialContext) {
    machineToInterpret = machineToInterpret.withContext(initialContext);
  }

  return interpret(machineToInterpret);
}
