
















































































































































import PhoneInput from '@/components/form/PhoneInput.vue';

import {
  getContactSizes, getEmployeeSizes, getLongevities, getObjectives,
} from '@/utils';
import * as validators from '@/utils/validators';

export default {
  name: 'Confirm',

  components: {
    PhoneInput,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      firstname: null,
      lastname: null,
      manualError: {
        name: false,
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        website: false,
        contacts: false,
        employees: false,
        longevity: false,
        // objective: false,
        password: false,
        terms: false,
        language: false,
        timezone: false,
      },
      showPassword: false,
    };
  },

  created() {
    if (!this.value.name) {
      return;
    }

    const splitted = this.value.name.trim().split(' ');
    const firstname = splitted.shift();
    const lastname = splitted.join(' ');

    this.firstname = firstname;
    this.lastname = lastname;
  },

  computed: {
    inputValue: {
      get():object {
        return this.value;
      },

      set(value):void {
        this.$emit('input', value);
      },
    },

    contacts: {
      get():number {
        const sizes = getContactSizes();

        return Object.values(sizes).indexOf(this.inputValue.contacts);
      },

      set(value):void {
        const sizes = getContactSizes();

        this.$emit('update-contacts', Object.values(sizes)[value]);
      },
    },

    employees: {
      get():number {
        const sizes = getEmployeeSizes();

        return Object.values(sizes).indexOf(this.inputValue.employees);
      },

      set(value):void {
        const sizes = getEmployeeSizes();

        this.$emit('update-employees', Object.values(sizes)[value]);
      },
    },

    longevity: {
      get():number {
        const longevities = getLongevities();

        return Object.values(longevities).indexOf(this.inputValue.longevity);
      },

      set(value):void {
        const longevities = getLongevities();

        this.$emit('update-longevity', Object.values(longevities)[value]);
      },
    },

    justify() {
      return ['xs'].includes(this.$mq) ? 'start' : 'center';
    },

    contactSizeText() {
      const values = getContactSizes();
      const selected = Object.keys(values).find(key => values[key] === this.inputValue.contacts);

      if (!selected) {
        return null;
      }

      return this.$t(`label.contactSize.${selected.toLowerCase()}`);
    },

    employeeSizeText() {
      const values = getEmployeeSizes();
      const selected = Object.keys(values).find(key => values[key] === this.inputValue.employees);

      if (!selected) {
        return null;
      }

      return this.$t(`label.employeeSize.${selected.toLowerCase()}`);
    },

    longevitySizeText() {
      const values = getLongevities();
      const selected = Object.keys(values).find(key => values[key] === this.inputValue.longevity);

      if (!selected) {
        return null;
      }

      return this.$t(`label.longevity.${selected.toLowerCase()}`);
    },

    objectivesItems() {
      const objectives = getObjectives();

      return objectives.map(objective => ({
        text: this.$t(objective.titleName),
        value: objective.icon,
      }));
    },

    phoneMessages() {
      if (!this.manualError.phone) {
        return [];
      }

      const result = this.valid(this.inputValue.phone, 'phone');

      return result === true ? [] : [result];
    },
  },

  watch: {
    firstname(value: string) {
      this.$emit('update-name', `${value} ${this.lastname}`);
    },

    lastname(value: string) {
      this.$emit('update-name', `${this.firstname} ${value}`);
    },
  },

  methods: {
    onNext() {
      this.$emit('clearSignupError');

      const validSteps = this.validForm(this.inputValue as object);

      if (validSteps.every(step => step.valid === true)) {
        this.$emit('next');
      } else {
        validSteps.map((step) => {
          this.manualError[step.step] = step.valid !== true;
          return null;
        });
      }
    },

    valid(value:any, type: string): boolean | string {
      let result;

      switch (type) {
        case 'firstname':
        case 'lastname':
          result = validators.validateName(value, type === 'firstname');
          break;
        case 'name':
          result = validators.validateFullName(value);
          break;

        case 'email':
          result = validators.validateEmail(value);
          break;

        case 'phone':
          result = validators.validatePhone(value.replace(/\s/g, ''));
          break;

        case 'website':
          result = validators.validateWebsite(value);
          break;

        case 'objective':
          result = validators.validateObjective(value);
          break;

        case 'password':
          result = validators.validatePassword(value);
          break;

        case 'contacts':
          result = validators.validateContacts(this.contacts);
          break;

        case 'employees':
          result = validators.validateEmployees(this.employees);
          break;

        case 'longevity':
          result = validators.validateLongevity(this.longevity);
          break;

        default:
          result = false;
          break;
      }

      if (type === 'terms' && typeof value === 'boolean') {
        result = validators.validateTerms(value);
      }

      return result === true
        ? result
        : this.$t(`error.${result}`);
    },

    validForm(form: object) {
      const keys = [...Object.keys(form), 'firstname', 'lastname'];

      return keys.map((key) => {
        if (key === 'timezone' || key === 'language') {
          return {
            step: key,
            valid: true,
          };
        }

        if (key === 'firstname' || key === 'lastname') {
          return {
            step: key,
            valid: this.valid(key === 'firstname' ? this.firstname : this.lastname, key),
          };
        }

        return {
          step: key,
          valid: this.valid(form[key], key),
        };
      });
    },

    addHTTPPrefixToWebsite() {
      if (validators.validateWebsite(this.inputValue.website, true)) {
        return;
      }

      if (validators.validateWebsite(`https://${this.inputValue.website}`, true)) {
        this.inputValue.website = `https://${this.inputValue.website}`;
      }
    },

    onWebsiteKeyUp(event) {
      this.addHTTPPrefixToWebsite();
    },
  },
};
