






























import FormContainer from '@/views/forms/container.vue';
import Testimonials from '@/views/Testimonials.vue';

export default {
  name: 'FreeTrial',

  components: {
    FormContainer,
    Testimonials,
  },
};
