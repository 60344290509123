import validator from 'validator';

const accents = require('remove-accents');

export function validateFullName(value:string): boolean | string {
  const trimmed = value.trim();

  if (!(!!trimmed && trimmed.split(' ').length >= 2)) {
    return 'fullNameRequired';
  }

  return true;
}

export function validateName(value:string, isFirstName: boolean): boolean | string {
  const trimmed = value.trim();

  if (!trimmed) {
    return isFirstName ? 'firstNameRequired' : 'nameRequired';
  }

  return true;
}

export function validateEmail(value:string): boolean | string {
  if (!value) {
    return 'emailRequired';
  }

  const emojisRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])+/i;

  return validator.isEmail(value, {
    allow_ip_domain: false,
    allow_utf8_local_part: false,
  }) && !emojisRegex.test(value) ? true : 'emailInvalid';
}

export function validatePhone(value:string): boolean | string {
  if (!value) {
    return 'phoneRequired';
  }

  // General loose validation on phone
  // Does not validate country code, just format
  const phoneRegex = new RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);

  return phoneRegex.test(value) ? true : 'phoneInvalid';
}

export function isValidAscii(value: string) {
  const invalidCharactersRegex = /[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+/i;
  const emojisRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])+/i;

  return !invalidCharactersRegex.test(value) && !emojisRegex.test(value);
}

export function validateWebsite(value:string, onlyValidate = false): boolean | string {
  if (!value && !onlyValidate) {
    return 'websiteRequired';
  }

  const isValid = validator.isURL(value, {
    protocols: ['http', 'https'],
    require_protocol: true,
    require_host: true,
    require_valid_protocol: true,
    allow_underscores: true,
  }) && isValidAscii(value);

  if (onlyValidate) {
    return isValid;
  }

  return isValid
    ? true
    : 'websiteInvalid';
}

export function validateContacts(value:number): boolean | string {
  return value > -1 || 'required';
}

export function validateEmployees(value:number): boolean | string {
  return value > -1 || 'required';
}

export function validateLongevity(value:number): boolean | string {
  return value > -1 || 'required';
}

export function validateObjective(value:string): boolean | string {
  return !!value || 'required';
}

export function validatePassword(value:string): boolean | string {
  const password = accents.remove(value);

  const lowerCase = /[a-z]+/g;
  const upperCase = /[A-Z]+/g;

  if (!password) {
    return 'passwordRequired';
  }

  if (password.length < 8) {
    return 'passwordMinLength';
  }

  if (!password.match(lowerCase)) {
    return 'passwordOneLowerCase';
  }

  if (!password.match(upperCase)) {
    return 'passwordOneUpperCase';
  }

  const emojisRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])+/i;
  if (emojisRegex.test(password)) {
    return 'passwordInvalid';
  }

  return true;
}

export function validateTerms(value:boolean): boolean | string {
  return value || 'required';
}
