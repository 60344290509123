










































import { getObjectives } from '@/utils';
import { validateObjective } from '@/utils/validators';

export default {
  name: 'Objective',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      manualError: false,
      objectives: getObjectives(),
    };
  },

  computed: {
    inputValue: {
      get():string {
        return this.value;
      },

      set(value):void {
        this.$emit('input', value);
      },
    },

    messages(): string[] {
      const result = this.valid(this.inputValue);

      if (this.manualError) {
        return [this.$t(`error.${result}`)];
      }

      return [this.$t('hint.objective')];
    },
  },

  methods: {
    onNext() {
      if (this.valid(this.inputValue as string) === true) {
        this.$emit('next');
      } else {
        this.manualError = true;
      }
    },

    valid(value:string): boolean | string {
      return validateObjective(value);
    },

    selectObjective(objective) {
      this.inputValue = objective.topic;
    },
  },
};
